import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { logout } from '../actions';
import { StoreContextProvider } from '../context';
import { StoreTabs, CartTabs, Loader, Message } from '../components';

import { Grid } from '@material-ui/core';

const StoreView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (
      !userInfo ||
      isTokenExpired(userInfo?.token) ||
      !userInfo?.storeAccess
    ) {
      dispatch(logout());
      history.push('/login');
    }
  }, [history, userInfo, dispatch]);

  return (
    <StoreContextProvider>
      <h1>Store</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity='error'>{error}</Message>
      ) : (
        <Grid container item md={12} spacing={1}>
          <Grid item md={7}>
            <StoreTabs />
          </Grid>
          <Grid item md={5}>
            <CartTabs />
          </Grid>
        </Grid>
      )}
    </StoreContextProvider>
  );
};

export { StoreView };
