// SCREEN KIT MAPPER
const scrKitMapper = (screen, kits, portalPricings) => {
  const {
    hardwareKits,
    widthKits,
    meshKits,
    motorKits,
    trackKits,
    barKits,
    angleKits,
  } = kits;
  const { options, measurements, meshOnly } = screen;
  const {
    screenType,
    jobColor,
    meshType,
    meshColor,
    motor,
    motorSide,
    tracks,
    barFinish,
    mountingAngle,
  } = options;
  const {
    width,
    widthFraction,
    widthDisplay,
    dropLeft,
    dropRight,
    dropLeftFraction,
    dropRightFraction,
    dropLeftDisplay,
    dropRightDisplay,
  } = measurements;

  // // console.log('scr', screen);
  // // console.log('pp', portalPricings);

  // // console.log('screenType', screenType.replace(/\\"/g, '"'));
  // // console.log('widthKits', widthKits);

  const extraHeight = () => {
    let isExtra = false;
    if (
      Number(dropLeft) > 132 ||
      (Number(dropLeft) === 132 && Number(dropLeftFraction) !== 0)
    ) {
      isExtra = true;
    }
    if (
      Number(dropRight) > 132 ||
      (Number(dropRight) === 132 && Number(dropRightFraction) !== 0)
    ) {
      isExtra = true;
    }
    return isExtra;
  };

  const lengthqty = () => {
    return Number(widthFraction) === 0
      ? Math.ceil(Number(width) / 12)
      : Math.ceil((Number(width) + 1) / 12);
  };

  let mappedScrKits = [];
  let pricedScreenKits = 0;
  // x.replace(/\\"/g, '"')
  if (!meshOnly) {
    const hardwareKit = hardwareKits
      .filter((kit) => kit.displayName.includes(jobColor))
      .filter((kit) => kit.displayName.includes(screenType));
    // // console.log('hardwareKit', hardwareKit);
    const widthKit = widthKits
      .filter((kit) => kit.displayName.includes(jobColor))
      .filter((kit) => kit.displayName.includes(screenType));
    const meshKit = meshKits
      .filter((kit) => kit.displayName.includes(meshType))
      .filter((kit) => kit.displayName.includes(meshColor));
    const motorKit = motorKits.filter((kit) => kit.displayName.includes(motor));
    const trackKit = extraHeight()
      ? trackKits
          .filter((kit) => kit.displayName.includes(jobColor))
          .filter((kit) => kit.displayName.includes(tracks))
          .filter((kit) => kit.displayName.toLowerCase().includes('extra'))
      : trackKits
          .filter((kit) => kit.displayName.includes(jobColor))
          .filter((kit) => kit.displayName.includes(tracks))
          .filter((kit) => !kit.displayName.toLowerCase().includes('extra'));
    const barKit =
      barFinish !== 'None'
        ? barKits.filter((kit) => kit.displayName.includes(barFinish))
        : null;
    const angleKit = mountingAngle
      ? extraHeight()
        ? angleKits
            .filter((kit) => kit.displayName.includes(jobColor))
            .filter((kit) => kit.displayName.toLowerCase().includes('extra'))
        : angleKits
            .filter((kit) => kit.displayName.includes(jobColor))
            .filter((kit) => !kit.displayName.toLowerCase().includes('extra'))
      : null;

    mappedScrKits = [
      hardwareKit && hardwareKit[0],
      widthKit && widthKit[0],
      meshKit && meshKit[0],
      motorKit && motorKit[0],
      trackKit && trackKit[0],
      barKit && barKit[0],
      angleKit && angleKit[0],
    ].filter((kit) => kit !== null);

    // // console.log('mappedScrKits', mappedScrKits);

    // // console.log(
    //   'Number(hardwareKit[0].customerPrice)',
    //   Number(hardwareKit[0].customerPrice)
    // );
    pricedScreenKits =
      Number(hardwareKit[0].customerPrice) +
      Number(widthKit[0].customerPrice) * lengthqty() +
      Number(meshKit[0].customerPrice) * lengthqty() +
      Number(motorKit[0].customerPrice) +
      Number(trackKit[0].customerPrice) +
      (barKit !== null ? Number(barKit[0].customerPrice) : 0) * lengthqty() +
      (angleKit !== null ? Number(angleKit[0].customerPrice) : 0);
  } else {
    const meshKit = meshKits
      .filter((kit) => kit.displayName.includes(meshType))
      .filter((kit) => kit.displayName.includes(meshColor));
    mappedScrKits = [meshKit && meshKit[0]];

    const ppMeshOnlyMethod = portalPricings.find((pp) =>
      pp.portalPricingName.includes('Mesh Only')
    ).portalPricingMethod;
    const ppMeshOnlyValue = portalPricings.find((pp) =>
      pp.portalPricingName.includes('Mesh Only')
    ).portalPricingValue;

    pricedScreenKits = Number(meshKit[0].customerPrice) * lengthqty();

    switch (ppMeshOnlyMethod) {
      case '+':
        pricedScreenKits += ppMeshOnlyValue;
        break;
      case '-':
        pricedScreenKits -= ppMeshOnlyValue;
        break;
      case '*':
        pricedScreenKits *= ppMeshOnlyValue;
        break;
      case '/':
        pricedScreenKits /= ppMeshOnlyValue;
        break;
      default:
        break;
    }
  }

  if (meshType === 'Vinyl') {
    const ppVinylMethod = portalPricings.find((pp) =>
      pp.portalPricingName.includes('Vinyl Screen Adj.')
    ).portalPricingMethod;
    const ppVinylValue = portalPricings.find((pp) =>
      pp.portalPricingName.includes('Vinyl Screen Adj.')
    ).portalPricingValue;

    switch (ppVinylMethod) {
      case '+':
        pricedScreenKits += ppVinylValue;
        break;
      case '-':
        pricedScreenKits -= ppVinylValue;
        break;
      case '*':
        pricedScreenKits *= ppVinylValue;
        break;
      case '/':
        pricedScreenKits /= ppVinylValue;
        break;
      default:
        break;
    }
  }
  // console.log('screenKits', kits);
  // console.log('mappedScrKits', mappedScrKits);
  // console.log('pricedScreenKits', pricedScreenKits);

  return {
    screenName: screen.screenName,
    screenId: screen.screenId,
    motorSide: motorSide,
    details: screen.details,
    screenWidth: widthDisplay,
    dropLeft: dropLeftDisplay,
    dropRight: dropRightDisplay,
    lengthqty: lengthqty,
    mappedScrKits: mappedScrKits,
    price: pricedScreenKits,
  };
};



export { scrKitMapper };
