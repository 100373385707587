import axios from 'axios';
import ObjectID from 'bson-objectid';

import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
} from '../constants';

export const createOrder =
  (order, orderName, orderNumber, status, isChecked, extraDetails) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const completeOrder = {
        ...order,
        orderId: order.orderId || ObjectID().toString(),
        orderName: orderName || order.orderName,
        orderNumber: orderNumber || order.orderNumber,
        status: status || order.status,
        transfer: isChecked || order.transfer,
        extraDetails: extraDetails || order.extraDetails,
        quoteId: '',
      };
      console.log(isChecked)
      const res = await axios.post('/api/orders', completeOrder, config);

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: {
          order: res.data,
          status: res.status,
          statusText: res.statusText,
        },
      });
    } catch (err) {
      dispatch({ type: ORDER_CREATE_FAIL, payload: err.message });
    }
  };

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get(`/api/orders/${id}`, config);
    // console.log('data: ', data);
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/orders/myorders', config);
    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    await axios.delete(`/api/orders/${id}`, config);
    dispatch({
      type: ORDER_DELETE_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
