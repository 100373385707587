import {
  BC_LIST_ITEMS_REQUEST,
  BC_LIST_ITEMS_SUCCESS,
  BC_LIST_ITEMS_FAIL,
  BC_SALES_QUOTE_REQUEST,
  BC_SALES_QUOTE_SUCCESS,
  BC_SALES_QUOTE_FAIL,
  BC_SALES_QUOTE_RESET,
} from '../constants';

export const bcItemsListReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_LIST_ITEMS_REQUEST:
      return { ...state, loading: true };
    case BC_LIST_ITEMS_SUCCESS:
      const itemsRaw = action.payload;

      // *****************************************************************************
      // portal items array (exclude hidden items)
      // *****************************************************************************
      const portalItems = itemsRaw.filter(
        (i) => !i.itemCategoryCode.toLowerCase().includes('(h)-')
      );

      // *****************************************************************************
      // parts array (exclude portal kits)
      // *****************************************************************************
      const items = portalItems.filter(
        (i) => !i.itemCategoryCode.toLowerCase().includes('pk-')
      );

      // *****************************************************************************
      // screen kits array (look for 'ret' portal kits only and chunk into sub-arrays)
      // *****************************************************************************
      const scrKits = portalItems.filter((i) =>
        i.itemCategoryCode.toLowerCase().includes('pk-ret')
      );
      const hardwareKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('hardware')
      );
      const widthKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('width')
      );
      const meshKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('mesh')
      );
      const motorKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('motor')
      );
      const trackKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('track')
      );
      const barKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('bar')
      );
      const angleKits = scrKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('mntangl')
      );
      const screenKits = {
        hardwareKits,
        widthKits,
        meshKits,
        motorKits,
        trackKits,
        barKits,
        angleKits,
      };

      // *****************************************************************************
      // roof kits arrays (look for 'lou' portal kits only and chunk into sub-arrays)
      // *****************************************************************************
      const rfKits = portalItems.filter((i) =>
        i.itemCategoryCode.toLowerCase().includes('pk-lou')
      );
      const frameKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('frame')
      );
      const mBeamKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('midbeam')
      );
      const aBeamKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('accbeam')
      );
      const xBeamKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('xtrabeam')
      );
      const bladeKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('blade')
      );
      const postKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('post')
      );
      const addPartsKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('addparts')
      );
      const roofMotorKits = rfKits.filter((k) =>
        k.itemCategoryCode.toLowerCase().includes('motor')
      );
      const roofKits = {
        frameKits,
        mBeamKits,
        aBeamKits,
        xBeamKits,
        bladeKits,
        postKits,
        addPartsKits,
        roofMotorKits,
      };

      // *****************************************************************************
      // exports arrays (portalItems with prices and no prices, hidden)
      // *****************************************************************************
      const itemsDollar = portalItems.filter((i) => i.customerPrice > 0);
      const itemsZeroDollar = portalItems.filter((i) => !i.customerPrice > 0);
      const itemsHidden = itemsRaw.filter((i) =>
        i.itemCategoryCode.toLowerCase().includes('(h)-')
      );

      return {
        loading: false,
        items,
        itemsZeroDollar,
        itemsDollar,
        itemsHidden,
        screenKits,
        roofKits,
      };

    case BC_LIST_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bcSalesQuoteInitReducer = (state = {}, action) => {
  switch (action.type) {
    case BC_SALES_QUOTE_REQUEST:
      return { loading: true };
    case BC_SALES_QUOTE_SUCCESS:
      return { loading: false, salesQuote: action.payload };
    case BC_SALES_QUOTE_FAIL:
      return { loading: false, error: action.payload };
    case BC_SALES_QUOTE_RESET:
      return {};

    default:
      return state;
  }
};
