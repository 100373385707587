// import React, { useEffect, useReducer, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory, useLocation } from 'react-router-dom';
// import { format } from 'date-fns';
// import readXlsxFile from 'read-excel-file';
// import XLSX from 'xlsx';

// import { Message, Loader, OrderList, TicketList } from '../components';

// import { numberFormat } from '../utils/numberFormatter';

// import {
//   adminUserList,
//   adminBcCustomerList,
//   // adminUpdateUser,
//   adminUpdateUsers,
//   adminUpdateBcCustomers,
//   // adminImportCustomers,
//   adminImportItems,
//   adminImportPrices,
//   adminGetPortalPricing,
//   adminSavePortalPricing,
//   adminGetCustomerTiers,
//   bcItemsList,
//   logout,
// } from '../actions';

// import {
//   Switch,
//   makeStyles,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Button,
//   ButtonGroup,
//   Select,
//   MenuItem,
//   Tab,
//   Box,
//   AppBar,
//   Tabs,
//   Card,
//   CardHeader,
//   CardContent,
//   CardActions,
//   FormControl,
//   TextField,
//   Grid,
//   InputLabel,
//   Input,
//   // Typography,
// } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
//   btns: {
//     display: 'flex',
//     flexDirection: 'row',
//   },
//   card: {
//     width: '50%',
//     borderRadius: 6,
//     // padding: 10,
//   },
//   formControl: {
//     marginTop: theme.spacing(),
//     marginBottom: theme.spacing(),
//     marginRight: theme.spacing(),
//     width: '99%',
//   },
// }));

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role='tabpanel'
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box p={3}>{children}</Box>}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

// const AdminView = () => {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const query = useQuery();

//   // const [bcId, setBcId] = useState();
//   // const [isApp, setIsApp] = useState();
//   // const [isAdm, setIsAdm] = useState();
//   const [value, setValue] = useState(0);
//   const [bcItemsPg, setBcItemsPg] = useState('');
//   const [unitOM, setUnitOM] = useState(undefined);
//   const [salesPrice, setSalesPrice] = useState(undefined);
//   // const [portalPricingName, setPortalPricingName] = useState('');
//   const [portalPricingValue, setPortalPricingValue] = useState(0);
//   const [portalPricingMethod, setPortalPricingMethod] = useState('');

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;
//   const adminUsers = useSelector((state) => state.adminUserList);
//   const { loading, error, users } = adminUsers;
//   const customers = useSelector((state) => state.adminBcCustomerList);
//   const { loading: bcLoading, bcCustomers } = customers;
//   const bcSalesQuoteInit = useSelector((state) => state.bcSalesQuoteInit);
//   const { loading: quoteLoading } = bcSalesQuoteInit;
//   const bcItems = useSelector((state) => state.bcItemsList);
//   const { itemsZeroDollar, itemsDollar, itemsHidden } = bcItems;
//   const adminPortalPricing = useSelector((state) => state.adminPortalPricing);
//   const { portalPricings } = adminPortalPricing;
//   const adminPortalPricingSave = useSelector(
//     (state) => state.adminPortalPricingSave
//   );
//   const adminCustomerTiers = useSelector((state) => state.adminCustomerTiers);
//   const { customerTiers } = adminCustomerTiers;

//   const [editedUsers, setEditedUsers] = useState(() => users);
//   const [editedBcCustomers, setEditedBcCustomers] = useState(() => customers);

//   function isTokenExpired(token) {
//     const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
//     return Math.floor(new Date().getTime() / 1000) >= expiry;
//   }

//   const {
//     // loading: adminPPSaveLoading,
//     // error: adminPPSaveError,
//     status: adminPPSaveStatus,
//   } = adminPortalPricingSave;

//   const acctTab = query.get('tab') === 'accounts';

//   const forceUpdate = useReducer((x) => x + 1, 0)[1];

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   useEffect(() => {
//     if (!userInfo || isTokenExpired(userInfo?.token) || !userInfo.isAdmin) {
//       dispatch(logout());
//       history.push('/login');
//     } else {
//       dispatch(adminUserList());
//       dispatch(adminBcCustomerList());
//       dispatch(adminGetPortalPricing());
//       dispatch(adminGetCustomerTiers());
//       if (acctTab) {
//         handleChange(null, 2);
//       }
//       if (adminPPSaveStatus && adminPPSaveStatus === 201) {
//         forceUpdate();
//       }
//     }
//   }, [history, userInfo, acctTab, adminPPSaveStatus, dispatch, forceUpdate]);

//   useEffect(() => {
//     setEditedUsers(users);
//     setEditedBcCustomers(customers);
//   }, [users, customers]);

//   // const saveLine = (rowId, e) => {
//   //   e.preventDefault();
//   //   dispatch(adminUpdateUser(rowId, bcId, isApp, isAdm));
//   //   window.location.reload();
//   // };

//   const handleEditUser = (e, index, key, value) => {
//     e.preventDefault();
//     const updatedUsers = [...editedUsers];
//     updatedUsers[index][key] = value;
//     // If storeAccess is toggled on, force portalAccess to be on
//     if (key === 'storeAccess' && value) {
//       updatedUsers[index]['portalAccess'] = true;
//     }
//     // If isAdmin is toggled on, force both portalAccess and storeAccess to be on
//     if (key === 'isAdmin' && value) {
//       updatedUsers[index]['portalAccess'] = true;
//       updatedUsers[index]['storeAccess'] = true;
//     }
//     setEditedUsers(updatedUsers);
//     // console.log('updatedUsers', updatedUsers);
//   };

//   const handleEditBcCustomer = (e, index, key, value) => {
//     e.preventDefault();
//     const updatedBcCustomers = [...editedBcCustomers];
//     updatedBcCustomers[index][key] = value;
//     setEditedBcCustomers(updatedBcCustomers);
//   };

//   const handleResetUsers = (e) => {
//     e.preventDefault();
//     window.location.reload();
//   };
//   const handleSaveUsers = (e) => {
//     e.preventDefault();
//     dispatch(adminUpdateUsers(editedUsers)).then(() => {
//       window.location.reload();
//     });
//     // window.location.reload();
//   };

//   const handleSaveBcCustomers = (e) => {
//     e.preventDefault();
//     dispatch(adminUpdateBcCustomers(editedBcCustomers)).then(() => {
//       window.location.reload();
//     });
//   };

//   const importCustomersHandler = (e) => {
//     // e.preventDefault();
//     // dispatch(adminImportCustomers());
//     // console.log('disabled for now');
//   };

//   const importItemsHandler = (e) => {
//     e.preventDefault();
//     dispatch(adminImportItems());
//   };

//   const importPricesHandler = (e) => {
//     e.preventDefault();
//     dispatch(adminImportPrices());
//   };

//   const handleBCItemsDispatch = (e) => {
//     e.preventDefault();
//     setBcItemsPg(e.target.value);
//     dispatch(bcItemsList(e.target.value));
//   };

//   const handleFileInput = (e) => {
//     const uomschema = {
//       'No.': {
//         prop: 'txtItemNo',
//         type: String,
//       },
//       'Base Unit of Measure': {
//         prop: 'txtBaseUnitOfMeasure',
//         type: String,
//       },
//       'Sales Unit of Measure': {
//         prop: 'txtSalesUnitOfMeasure',
//         type: String,
//       },
//       'Purch. Unit of Measure': {
//         prop: 'txtPurchUnitOfMeasure',
//         type: String,
//       },
//     };
//     const spschema = {
//       'No.': {
//         prop: 'txtItemNo',
//         type: String,
//       },
//       'Canadian Sales Price': {
//         prop: 'txtCAD',
//         type: Number,
//       },
//       'US Sales Price': {
//         prop: 'txtUSD',
//         type: Number,
//       },
//     };
//     e.preventDefault();
//     const fileName = e.target.files[0].name;
//     if (fileName === 'uom.xlsx') {
//       const schema = uomschema;
//       readXlsxFile(e.target.files[0], { schema }).then((rows) => {
//         setUnitOM(rows);
//       });
//     } else if (fileName === 'salesprice.xlsx') {
//       const schema = spschema;
//       readXlsxFile(e.target.files[0], { schema }).then((rows) => {
//         setSalesPrice(rows);
//       });
//     }
//   };

//   const handleFileOutput = async (e) => {
//     e.preventDefault();
//     const { rows: uomRows } = unitOM;
//     const { rows: spRows } = salesPrice;
//     // // console.log('uom', uomRows);
//     // // console.log('sp', spRows);
//     const fileName = 'salespricepackage.xlsx';

//     const spData = () => {
//       let pData = [];
//       let uData = [];
//       spRows.map((price) => {
//         uData = uomRows.filter((uom) => uom.txtItemNo === price.txtItemNo);
//         if (uData.length > 0) {
//           if (price.txtCAD?.toString().length > 0) {
//             pData.push({
//               'Item No.': price.txtItemNo,
//               'Sales Type': 'Customer Price Group',
//               'Sales Code': 'CAD',
//               'Starting Date': '2022-01-01',
//               'Currency Code': 'CAD',
//               'Variant Code': '',
//               'Unit of Measure Code': uData[0].txtSalesUnitOfMeasure,
//               'Minimum Quantity': 0,
//               'Unit Price': Number(price.txtCAD),
//               'Price Includes VAT': false,
//               'Allow Invoice Disc.': true,
//               'Allow Line Disc.': true,
//             });
//           }
//           if (price.txtUSD?.toString().length > 0) {
//             pData.push({
//               'Item No.': price.txtItemNo,
//               'Sales Type': 'Customer Price Group',
//               'Sales Code': 'USD',
//               'Starting Date': '2022-01-01',
//               'Currency Code': 'USD',
//               'Variant Code': '',
//               'Unit of Measure Code': uData[0].txtSalesUnitOfMeasure,
//               'Minimum Quantity': 0,
//               'Unit Price': Number(price.txtUSD),
//               'Price Includes VAT': false,
//               'Allow Invoice Disc.': true,
//               'Allow Line Disc.': true,
//             });
//           }
//         }
//         return pData;
//       });
//       return pData;
//     };

//     const objects = unitOM && salesPrice && spData();
//     // .filter((item) => item['Unit Price'] !== 0);

//     const worksheet = XLSX.utils.json_to_sheet(objects);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Price');
//     XLSX.writeFile(workbook, fileName);
//   };

//   const handleItemsFromPortal = (e) => {
//     e.preventDefault();

//     const xlsxFromPortal = XLSX.utils.book_new();
//     const fileName = `[${format(
//       new Date(),
//       'MMM dd, yyyy'
//     )}] Items from Portal (${bcItemsPg}).xlsx`;

//     let xId = [];
//     const xlsxID =
//       itemsDollar?.length > 0 &&
//       itemsDollar.map((item) => {
//         xId.push({
//           bcId: item.id,
//           number: item.number,
//           displayName: item.displayName,
//           baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
//           salesUnitOfMeasureCode: item.customerUnitMeasure,
//           customerPrice: numberFormat(
//             'cur-display',
//             Number(item.customerPrice)
//           ),
//           itemCategoryCode: item.itemCategoryCode,
//           type: item.type,
//           createdAt: format(new Date(item.createdAt), 'MMM dd, yyyy'),
//           updatedAt: format(new Date(item.updatedAt), 'MMM dd, yyyy'),
//         });
//         return xId;
//       });
//     const xlsxIDSheet =
//       xlsxID?.length > 0 && XLSX.utils.json_to_sheet(xlsxID[0]);
//     xlsxID?.length > 0 &&
//       XLSX.utils.book_append_sheet(
//         xlsxFromPortal,
//         xlsxIDSheet,
//         'Items with Prices'
//       );

//     let xIzd = [];
//     const xlsxIZD =
//       itemsZeroDollar?.length > 0 &&
//       itemsZeroDollar.map((item) => {
//         xIzd.push({
//           bcId: item.id,
//           number: item.number,
//           displayName: item.displayName,
//           baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
//           salesUnitOfMeasureCode: item.customerUnitMeasure,
//           customerPrice: numberFormat(
//             'cur-display',
//             Number(item.customerPrice)
//           ),
//           itemCategoryCode: item.itemCategoryCode,
//           type: item.type,
//           createdAt: format(new Date(item.createdAt), 'MMM dd, yyyy'),
//           updatedAt: format(new Date(item.updatedAt), 'MMM dd, yyyy'),
//         });
//         return xIzd;
//       });
//     const xlsxIZDSheet =
//       xlsxIZD?.length > 0 && XLSX.utils.json_to_sheet(xlsxIZD[0]);
//     XLSX.utils.book_append_sheet(
//       xlsxFromPortal,
//       xlsxIZDSheet,
//       'Non-Hidden Items Missing Prices'
//     );

//     let xIh = [];
//     const xlsxIH =
//       itemsHidden?.length > 0 &&
//       itemsHidden.map((item) => {
//         xIh.push({
//           bcId: item.id,
//           number: item.number,
//           displayName: item.displayName,
//           baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
//           salesUnitOfMeasureCode: item.customerUnitMeasure,
//           customerPrice: numberFormat(
//             'cur-display',
//             Number(item.customerPrice)
//           ),
//           itemCategoryCode: item.itemCategoryCode,
//           type: item.type,
//           createdAt: format(new Date(item.createdAt), 'MMM dd, yyyy'),
//           updatedAt: format(new Date(item.updatedAt), 'MMM dd, yyyy'),
//         });
//         return xIh;
//       });
//     const xlsxIHSheet =
//       xlsxIH?.length > 0 && XLSX.utils.json_to_sheet(xlsxIH[0]);
//     XLSX.utils.book_append_sheet(xlsxFromPortal, xlsxIHSheet, 'Hidden Items');

//     XLSX.writeFile(xlsxFromPortal, fileName);
//   };

//   const pricingAccess = ['devops@suncoastbuilt.com', 'devin@suncoastbuilt.com'];

//   const handlePortalPricingSave = (e, p) => {
//     e.preventDefault();
//     let pricing = {
//       _id: p._id,
//       portalPricingValue: !portalPricingValue
//         ? p.portalPricingValue
//         : portalPricingValue,
//       portalPricingMethod: !portalPricingMethod
//         ? p.portalPricingMethod
//         : portalPricingMethod,
//     };
//     dispatch(adminSavePortalPricing(pricing));
//   };

//   return (
//     <>
//       <h1>Admin</h1>
//       {loading ? (
//         <Loader />
//       ) : error ? (
//         <Message severity='error'>{error}</Message>
//       ) : (
//         <div className={classes.root}>
//           <AppBar position='static' color='secondary'>
//             <Tabs
//               value={value}
//               onChange={handleChange}
//               aria-label='simple tabs example'
//             >
//               <Tab label='Dealer Orders' {...a11yProps(0)} />
//               {/* <Tab label='Shipped Orders' {...a11yProps(1)} /> */}
//               <Tab label='Dealer Tickets' {...a11yProps(1)} />
//               <Tab label='Portal Users' {...a11yProps(2)} />
//               {userInfo?.email.length > 0 &&
//                 pricingAccess.includes(userInfo.email) && (
//                   <Tab label='Pricing' {...a11yProps(3)} />
//                 )}
//               {userInfo?.email.length > 0 &&
//                 userInfo.email === 'devops@suncoastbuilt.com' && (
//                   <Tab label='Data Management' {...a11yProps(4)} />
//                 )}
//             </Tabs>
//           </AppBar>
//           <TabPanel value={value} index={0}>
//             {quoteLoading ? (
//               <Loader />
//             ) : (
//               <OrderList admin={true} shipped={false} />
//             )}
//           </TabPanel>
//           {/* <TabPanel value={value} index={1}>
//             <OrderList admin={true} shipped={true} />
//           </TabPanel> */}
//           <TabPanel value={value} index={1}>
//             {<TicketList admin />}
//           </TabPanel>
//           <TabPanel value={value} index={2}>
//             <h1>Portal Users</h1>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead className={classes.table} aria-label='simple table'>
//                   <TableRow>
//                     <TableCell>Portal User</TableCell>
//                     <TableCell>Email</TableCell>
//                     <TableCell>Business Central Customer</TableCell>
//                     <TableCell>Has Portal Access?</TableCell>
//                     <TableCell>Has Store Access?</TableCell>
//                     <TableCell>Is a Portal Admin?</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {editedUsers?.length > 0 &&
//                     editedUsers.map((user, index) => (
//                       <TableRow key={user._id}>
//                         <TableCell>{user.fname + ' ' + user.lname}</TableCell>
//                         <TableCell>{user.email}</TableCell>
//                         <TableCell>
//                           {bcLoading ? (
//                             <Loader />
//                           ) : (
//                             bcCustomers && (
//                               <Select
//                                 value={user.bc_customer_id}
//                                 onChange={(e) =>
//                                   handleEditUser(
//                                     e,
//                                     index,
//                                     'bc_customer_id',
//                                     e.target.value
//                                   )
//                                 }
//                               >
//                                 {bcCustomers.map((cust) => (
//                                   <MenuItem
//                                     key={cust.bc_customer_id}
//                                     value={cust.bc_customer_id}
//                                   >
//                                     {cust.bc_customer_displayName}
//                                   </MenuItem>
//                                 ))}
//                               </Select>
//                             )
//                           )}
//                         </TableCell>
//                         <TableCell>
//                           <Switch
//                             checked={user.isApproved}
//                             disabled={
//                               user.storeAccess ||
//                               user.isAdmin ||
//                               user.bc_customer_id === '000'
//                             }
//                             onChange={(e) =>
//                               handleEditUser(
//                                 e,
//                                 index,
//                                 'isApproved',
//                                 e.target.checked
//                               )
//                             }
//                             name='isApproved'
//                             id={'isApproved' + user._id}
//                             color='primary'
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <Switch
//                             checked={user.storeAccess}
//                             disabled={user.isAdmin || user.isApproved === false}
//                             onChange={(e) =>
//                               handleEditUser(
//                                 e,
//                                 index,
//                                 'storeAccess',
//                                 e.target.checked
//                               )
//                             }
//                             name='storeAccess'
//                             id={'storeAccess' + user._id}
//                             color='primary'
//                           />
//                         </TableCell>
//                         <TableCell>
//                           <Switch
//                             checked={user.isAdmin}
//                             disabled={user.isApproved === false}
//                             onChange={(e) =>
//                               handleEditUser(
//                                 e,
//                                 index,
//                                 'isAdmin',
//                                 e.target.checked
//                               )
//                             }
//                             name='isAdmin'
//                             id={'isAdmin' + user._id}
//                             color='secondary'
//                           />
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <Box display='flex'>
//               <ButtonGroup
//                 style={{
//                   marginLeft: 'auto',
//                   marginTop: 10,
//                 }}
//               >
//                 <Button
//                   variant='contained'
//                   color='primary'
//                   size='small'
//                   style={{ marginRight: 5 }}
//                   onClick={handleResetUsers}
//                 >
//                   Reset Users
//                 </Button>
//                 <Button
//                   variant='contained'
//                   color='secondary'
//                   size='small'
//                   onClick={handleSaveUsers}
//                 >
//                   Save Users
//                 </Button>
//               </ButtonGroup>
//             </Box>
//           </TabPanel>
//           {userInfo?.email.length > 0 &&
//             pricingAccess.includes(userInfo.email) && (
//               <TabPanel value={value} index={3}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <h1>Pricing</h1>
//                   </Grid>
//                   {portalPricings?.length > 0 &&
//                     portalPricings.map((p, i) => (
//                       <Grid item xs={3} key={i}>
//                         <Box display='flex'>
//                           <Card variant='outlined'>
//                             <CardHeader title={p.portalPricingName} />
//                             <CardContent>
//                               <FormControl
//                                 className={classes.formControl}
//                                 required
//                               >
//                                 {/* <InputLabel shrink>Amount</InputLabel> */}
//                                 <TextField
//                                   type='number'
//                                   defaultValue={p.portalPricingValue}
//                                   InputLabelProps={{
//                                     shrink: true,
//                                   }}
//                                   label='Amount'
//                                   onFocus={(e) => e.target.select()}
//                                   onChange={(e) =>
//                                     setPortalPricingValue(e.target.value)
//                                   }
//                                 />
//                               </FormControl>
//                               <FormControl
//                                 className={classes.formControl}
//                                 required
//                                 // fullWidth
//                               >
//                                 <InputLabel shrink>Method</InputLabel>
//                                 <Select
//                                   // name='postLength'
//                                   required
//                                   defaultValue={p.portalPricingMethod}
//                                   onChange={(e) =>
//                                     setPortalPricingMethod(e.target.value)
//                                   }
//                                 >
//                                   {p.portalPricingMethods?.map((m, i) => (
//                                     <MenuItem
//                                       key={i}
//                                       value={m.portalPricingMethodValue}
//                                     >
//                                       {m.portalPricingMethodName}
//                                     </MenuItem>
//                                   ))}
//                                 </Select>
//                               </FormControl>
//                             </CardContent>
//                             <CardActions>
//                               <Button
//                                 variant='contained'
//                                 color='secondary'
//                                 size='small'
//                                 onClick={(e) => handlePortalPricingSave(e, p)}
//                                 style={{ marginLeft: 'auto' }}
//                               >
//                                 Save
//                               </Button>
//                             </CardActions>
//                           </Card>
//                         </Box>
//                       </Grid>
//                     ))}
//                   <Grid item xs={12}>
//                     <h1>Customer Tiers</h1>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <TableContainer component={Paper}>
//                       <Table>
//                         <TableHead
//                           className={classes.table}
//                           aria-label='simple table'
//                         >
//                           <TableRow>
//                             <TableCell>BC Customer</TableCell>
//                             <TableCell>Customer Tier</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {bcCustomers?.length > 0 &&
//                             bcCustomers
//                               .filter(
//                                 (c) => c.bc_customer_displayName !== 'unmatched'
//                               )
//                               .map((c, i) => (
//                                 <TableRow key={i}>
//                                   <TableCell>
//                                     {c.bc_customer_displayName}
//                                   </TableCell>
//                                   <TableCell>
//                                     <FormControl
//                                       className={classes.formControl}
//                                       required
//                                     >
//                                       <Select
//                                         name='customerTier'
//                                         required
//                                         defaultValue={c.customerTier._id}
//                                         onChange={(e) =>
//                                           handleEditBcCustomer(
//                                             e,
//                                             i,
//                                             'customerTier',
//                                             e.target.value
//                                           )
//                                         }
//                                       >
//                                         {customerTiers?.map((t, i) => (
//                                           <MenuItem key={i} value={t._id}>
//                                             {t.customerTierName}
//                                           </MenuItem>
//                                         ))}
//                                       </Select>
//                                     </FormControl>
//                                   </TableCell>
//                                 </TableRow>
//                               ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Grid>
//                 </Grid>
//               </TabPanel>
//             )}
//           {userInfo?.email.length > 0 && userInfo.email === 'devops@suncoastbuilt.com' && (
//             <TabPanel value={value} index={4}>
//               <h1>Data Management</h1>
//               <Card className={classes.card}>
//                 <CardHeader title='Data Imports' />
//                 <CardActions>
//                   <Button
//                     variant='contained'
//                     color='primary'
//                     disabled
//                     onClick={(e) => importCustomersHandler(e)}
//                   >
//                     Import Customers
//                   </Button>
//                   <Button
//                     variant='contained'
//                     color='primary'
//                     // style={{ marginLeft: 5, marginRight: 5 }}
//                     onClick={(e) => importItemsHandler(e)}
//                   >
//                     Import Items
//                   </Button>
//                   <Button
//                     variant='contained'
//                     color='primary'
//                     onClick={(e) => importPricesHandler(e)}
//                   >
//                     Import Prices
//                   </Button>
//                 </CardActions>
//               </Card>
//               <Card className={classes.card} style={{ marginTop: 15 }}>
//                 <CardHeader title='Data Exports' />
//                 <CardContent>
//                   <Select
//                     value={bcItemsPg}
//                     onChange={(e) => handleBCItemsDispatch(e)}
//                     displayEmpty
//                   >
//                     <MenuItem disabled value=''>
//                       <em>Select a PriceGroup</em>
//                     </MenuItem>
//                     <MenuItem value='CAD'>CAD</MenuItem>
//                     <MenuItem value='USD'>USD</MenuItem>
//                     <MenuItem value='SUN'>SUN</MenuItem>
//                   </Select>
//                 </CardContent>
//                 <CardActions>
//                   <Button
//                     variant='contained'
//                     color='secondary'
//                     onClick={(e) => handleItemsFromPortal(e)}
//                   >
//                     Portal Items Export
//                   </Button>
//                 </CardActions>
//               </Card>
//               <Card className={classes.card} style={{ marginTop: 15 }}>
//                 <CardHeader title='ItemsSheet Generator for BC' />
//                 <CardContent>
//                   <form>
//                     <FormControl>
//                       <InputLabel shrink>UnitOM Import</InputLabel>
//                       <Input
//                         id='unitOMIn'
//                         type={'file'}
//                         label='UnitOM Import'
//                         onChange={(e) => handleFileInput(e)}
//                       />
//                     </FormControl>
//                     <FormControl style={{ marginLeft: 5 }}>
//                       <InputLabel shrink>Sales Price Import</InputLabel>
//                       <Input
//                         id='salesPriceIn'
//                         type={'file'}
//                         onChange={(e) => handleFileInput(e)}
//                       ></Input>
//                     </FormControl>
//                   </form>
//                 </CardContent>
//                 <CardActions>
//                   {unitOM && salesPrice && (
//                     <Button
//                       variant='contained'
//                       color='secondary'
//                       style={{ marginTop: 15 }}
//                       onClick={(e) => handleFileOutput(e)}
//                     >
//                       Download
//                     </Button>
//                   )}
//                 </CardActions>
//               </Card>
//             </TabPanel>
//           )}
//         </div>
//       )}
//     </>
//   );
// };

// export { AdminView };

import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import readXlsxFile from 'read-excel-file';
import XLSX from 'xlsx';

import { Message, Loader, OrderList, TicketList } from '../components';

import { numberFormat } from '../utils/numberFormatter';

import {
  adminUserList,
  adminBcCustomerList,
  adminUpdateUsers,
  adminUpdateBcCustomers,
  adminImportItems,
  adminImportPrices,
  adminGetPortalPricing,
  adminSavePortalPricing,
  adminGetCustomerTiers,
  bcItemsList,
  logout,
} from '../actions';

import {
  Switch,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  ButtonGroup,
  Select,
  MenuItem,
  Tab,
  Box,
  AppBar,
  Tabs,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Input,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    width: '50%',
    borderRadius: 6,
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AdminView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const [value, setValue] = useState(0);
  const [bcItemsPg, setBcItemsPg] = useState('');
  const [unitOM, setUnitOM] = useState(undefined);
  const [salesPrice, setSalesPrice] = useState(undefined);
  const [portalPricingValue, setPortalPricingValue] = useState(0);
  const [portalPricingMethod, setPortalPricingMethod] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const adminUsers = useSelector((state) => state.adminUserList);
  const { loading, error, users } = adminUsers;
  const customers = useSelector((state) => state.adminBcCustomerList);
  const { loading: bcLoading, bcCustomers } = customers;
  const bcSalesQuoteInit = useSelector((state) => state.bcSalesQuoteInit);
  const { loading: quoteLoading } = bcSalesQuoteInit;
  const bcItems = useSelector((state) => state.bcItemsList);
  const { itemsZeroDollar, itemsDollar, itemsHidden } = bcItems;
  const adminPortalPricing = useSelector((state) => state.adminPortalPricing);
  const { portalPricings } = adminPortalPricing;
  const adminPortalPricingSave = useSelector(
    (state) => state.adminPortalPricingSave
  );
  const adminCustomerTiers = useSelector((state) => state.adminCustomerTiers);
  const { customerTiers } = adminCustomerTiers;

  const [editedUsers, setEditedUsers] = useState( []);
  const [userList, setUserList] = useState( []);
  const [editedBcCustomers, setEditedBcCustomers] = useState(bcCustomers || []);
  const [editedBcCustomersTiers, setEditedBcCustomersTiers] = useState(customerTiers || []);

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  const { status: adminPPSaveStatus } = adminPortalPricingSave;

  const acctTab = query.get('tab') === 'accounts';

  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo?.token) || !userInfo.isAdmin) {
      dispatch(logout());
      history.push('/login');
    } else {
      dispatch(adminUserList());
      dispatch(adminBcCustomerList());
      dispatch(adminGetPortalPricing());
      dispatch(adminGetCustomerTiers());
      if (acctTab) {
        handleChange(null, 2);
      }
      if (adminPPSaveStatus && adminPPSaveStatus === 201) {
        forceUpdate();
      }
    }
  }, [history, userInfo, acctTab, adminPPSaveStatus, dispatch, forceUpdate]);

  useEffect(() => {
    setUserList(users);
    setEditedBcCustomers(
      bcCustomers?.filter((c) => c.bc_customer_displayName !== 'unmatched')
    );
    setEditedBcCustomers(
      bcCustomers?.sort((a, b) => {
        const nameA = a.bc_customer_displayName.toUpperCase();
        const nameB = b.bc_customer_displayName.toUpperCase(); 
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
    );
    setEditedBcCustomersTiers(customerTiers?.sort((a,b) => {
      return b.customerTierRate - a.customerTierRate;
    }))

  }, [users, bcCustomers, customerTiers]);

  const handleEditUser = (e, index, key, value) => {
    e.preventDefault();
    
    const updatedUsers = [...userList]
    updatedUsers.push();
    updatedUsers[index][key] = value;
    // If storeAccess is toggled on, force portalAccess to be on
    if (key === 'storeAccess' && value) {
      updatedUsers[index]['portalAccess'] = true;
    }
    // If isAdmin is toggled on, force both portalAccess and storeAccess to be on
    if (key === 'isAdmin' && value) {
      updatedUsers[index]['portalAccess'] = true;
      updatedUsers[index]['storeAccess'] = true;
    }
    let usersToUpdate = editedUsers
    const alreadyChanged = usersToUpdate.findIndex(item => item._id === updatedUsers[index]._id);

    if (alreadyChanged !== -1) {
      usersToUpdate[alreadyChanged] = updatedUsers[index];
    } else {
      usersToUpdate.push(updatedUsers[index]);
    }
    setUserList(updatedUsers);
    setEditedUsers(usersToUpdate)
    // console.log('updatedUsers', updatedUsers);
  };

  const handleEditBcCustomer = (e, index, key, customerTierId) => {
    e.preventDefault();
    const updatedBcCustomers = [...editedBcCustomers];

    // Find the selected customerTier object
    const selectedCustomerTier = customerTiers.find(
      (tier) => tier._id === customerTierId
    );

    // Update the bcCustomer with the entire customerTier object
    if (selectedCustomerTier) {
      updatedBcCustomers[index][key] = selectedCustomerTier;
    }

    setEditedBcCustomers(updatedBcCustomers);
  };

  const handleResetUsers = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const handleSaveUsers = (e) => {
    e.preventDefault();
    dispatch(adminUpdateUsers(editedUsers))
  };

  const handleSaveBcCustomers = (e) => {
    e.preventDefault();
    // console.log('editedBcCustomers', editedBcCustomers);
    dispatch(adminUpdateBcCustomers(editedBcCustomers)).then(() => {
      // window.location.reload();
    });
  };

  const importCustomersHandler = (e) => {
    // console.log('disabled for now');
  };

  const importItemsHandler = (e) => {
    e.preventDefault();
    dispatch(adminImportItems());
  };

  const importPricesHandler = (e) => {
    e.preventDefault();
    dispatch(adminImportPrices());
  };

  const handleBCItemsDispatch = (e) => {
    e.preventDefault();
    setBcItemsPg(e.target.value);
    dispatch(bcItemsList(e.target.value));
  };

  const handleFileInput = (e) => {
    const uomschema = {
      'No.': {
        prop: 'txtItemNo',
        type: String,
      },
      'Base Unit of Measure': {
        prop: 'txtBaseUnitOfMeasure',
        type: String,
      },
      'Sales Unit of Measure': {
        prop: 'txtSalesUnitOfMeasure',
        type: String,
      },
      'Purch. Unit of Measure': {
        prop: 'txtPurchUnitOfMeasure',
        type: String,
      },
    };
    const spschema = {
      'No.': {
        prop: 'txtItemNo',
        type: String,
      },
      'Canadian Sales Price': {
        prop: 'txtCAD',
        type: Number,
      },
      'US Sales Price': {
        prop: 'txtUSD',
        type: Number,
      },
    };
    e.preventDefault();
    const fileName = e.target.files[0].name;
    if (fileName === 'uom.xlsx') {
      const schema = uomschema;
      readXlsxFile(e.target.files[0], { schema }).then((rows) => {
        setUnitOM(rows);
      });
    } else if (fileName === 'salesprice.xlsx') {
      const schema = spschema;
      readXlsxFile(e.target.files[0], { schema }).then((rows) => {
        setSalesPrice(rows);
      });
    }
  };

  const handleFileOutput = async (e) => {
    e.preventDefault();
    const { rows: uomRows } = unitOM;
    const { rows: spRows } = salesPrice;
    const fileName = 'salespricepackage.xlsx';

    const spData = () => {
      let pData = [];
      let uData = [];
      spRows.map((price) => {
        uData = uomRows.filter((uom) => uom.txtItemNo === price.txtItemNo);
        if (uData.length > 0) {
          if (price.txtCAD?.toString().length > 0) {
            pData.push({
              'Item No.': price.txtItemNo,
              'Sales Type': 'Customer Price Group',
              'Sales Code': 'CAD',
              'Starting Date': '2022-01-01',
              'Currency Code': 'CAD',
              'Variant Code': '',
              'Unit of Measure Code': uData[0].txtSalesUnitOfMeasure,
              'Minimum Quantity': 0,
              'Unit Price': Number(price.txtCAD),
              'Price Includes VAT': false,
              'Allow Invoice Disc.': true,
              'Allow Line Disc.': true,
            });
          }
          if (price.txtUSD?.toString().length > 0) {
            pData.push({
              'Item No.': price.txtItemNo,
              'Sales Type': 'Customer Price Group',
              'Sales Code': 'USD',
              'Starting Date': '2022-01-01',
              'Currency Code': 'USD',
              'Variant Code': '',
              'Unit of Measure Code': uData[0].txtSalesUnitOfMeasure,
              'Minimum Quantity': 0,
              'Unit Price': Number(price.txtUSD),
              'Price Includes VAT': false,
              'Allow Invoice Disc.': true,
              'Allow Line Disc.': true,
            });
          }
        }
        return pData;
      });
      return pData;
    };

    const objects = unitOM && salesPrice && spData();

    const worksheet = XLSX.utils.json_to_sheet(objects);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Price');
    XLSX.writeFile(workbook, fileName);
  };

  const handleItemsFromPortal = (e) => {
    e.preventDefault();

    const xlsxFromPortal = XLSX.utils.book_new();
    const fileName = `[${format(
      new Date(),
      'MMM dd, yyyy'
    )}] Items from Portal (${bcItemsPg}).xlsx`;

    let xId = [];
    const xlsxID =
      itemsDollar?.length > 0 &&
      itemsDollar.map((item) => {
        xId.push({
          bcId: item.id,
          number: item.number,
          displayName: item.displayName,
          baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          salesUnitOfMeasureCode: item.customerUnitMeasure,
          customerPrice: numberFormat(
            'cur-display',
            Number(item.customerPrice)
          ),
          itemCategoryCode: item.itemCategoryCode,
          type: item.type,
          createdAt: format(new Date(item.createdAt), 'MMM dd, yyyy'),
          updatedAt: format(new Date(item.updatedAt), 'MMM dd, yyyy'),
        });
        return xId;
      });
    const xlsxIDSheet =
      xlsxID?.length > 0 && XLSX.utils.json_to_sheet(xlsxID[0]);
    xlsxID?.length > 0 &&
      XLSX.utils.book_append_sheet(
        xlsxFromPortal,
        xlsxIDSheet,
        'Items with Prices'
      );

    let xIzd = [];
    const xlsxIZD =
      itemsZeroDollar?.length > 0 &&
      itemsZeroDollar.map((item) => {
        xIzd.push({
          bcId: item.id,
          number: item.number,
          displayName: item.displayName,
          baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          salesUnitOfMeasureCode: item.customerUnitMeasure,
          customerPrice: numberFormat(
            'cur-display',
            Number(item.customerPrice)
          ),
          itemCategoryCode: item.itemCategoryCode,
          type: item.type,
          createdAt: format(new Date(item.createdAt), 'MMM dd, yyyy'),
          updatedAt: format(new Date(item.updatedAt), 'MMM dd, yyyy'),
        });
        return xIzd;
      });
    const xlsxIZDSheet =
      xlsxIZD?.length > 0 && XLSX.utils.json_to_sheet(xlsxIZD[0]);
    XLSX.utils.book_append_sheet(
      xlsxFromPortal,
      xlsxIZDSheet,
      'Non-Hidden Items Missing Prices'
    );

    let xIh = [];
    const xlsxIH =
      itemsHidden?.length > 0 &&
      itemsHidden.map((item) => {
        xIh.push({
          bcId: item.id,
          number: item.number,
          displayName: item.displayName,
          baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
          salesUnitOfMeasureCode: item.customerUnitMeasure,
          customerPrice: numberFormat(
            'cur-display',
            Number(item.customerPrice)
          ),
          itemCategoryCode: item.itemCategoryCode,
          type: item.type,
          createdAt: format(new Date(item.createdAt), 'MMM dd, yyyy'),
          updatedAt: format(new Date(item.updatedAt), 'MMM dd, yyyy'),
        });
        return xIh;
      });
    const xlsxIHSheet =
      xlsxIH?.length > 0 && XLSX.utils.json_to_sheet(xlsxIH[0]);
    XLSX.utils.book_append_sheet(xlsxFromPortal, xlsxIHSheet, 'Hidden Items');

    XLSX.writeFile(xlsxFromPortal, fileName);
  };

  const pricingAccess = ['devops@suncoastbuilt.com', 'devin@suncoastbuilt.com'];

  const handlePortalPricingSave = (e, p) => {
    e.preventDefault();
    let pricing = {
      _id: p._id,
      portalPricingValue: !portalPricingValue
        ? p.portalPricingValue
        : portalPricingValue,
      portalPricingMethod: !portalPricingMethod
        ? p.portalPricingMethod
        : portalPricingMethod,
    };
    dispatch(adminSavePortalPricing(pricing));
  };

  const sortUsers = (order, orderBy) => {
    if (orderBy === "Portal User") {
      orderBy = "fname"
    }
    const sorted = [...users].sort((a, b) => {
      const aValue = orderBy === 'Business Central Customer' ? bcCustomers.find((customer) => customer.bc_customer_id === a['bc_customer_id']).bc_customer_displayName : a[orderBy];
      const bValue = orderBy === 'Business Central Customer' ? bcCustomers.find((customer) => customer.bc_customer_id === b['bc_customer_id']).bc_customer_displayName : b[orderBy];
      if (order === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });
    setUserList(sorted)

  };
  const handleSort = (property) => {
    const isAscending = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
    sortUsers(isAscending ? 'desc' : 'asc', property);
  };

  // console.log('bcCustomers', bcCustomers);
  // console.log('customerTiers', customerTiers);

  return (
    <>
      <h1>Admin</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message severity='error'>{error}</Message>
      ) : (
        <div className={classes.root}>
          <AppBar position='static' color='secondary'>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='simple tabs example'
            >
              <Tab label='Dealer Orders' {...a11yProps(0)} />
              <Tab label='Dealer Tickets' {...a11yProps(1)} />
              <Tab label='Portal Users' {...a11yProps(2)} />
              {userInfo?.email.length > 0 &&
                pricingAccess.includes(userInfo.email) && (
                  <Tab label='Pricing' {...a11yProps(3)} />
                )}
              {userInfo?.email.length > 0 &&
                userInfo.email === 'devops@suncoastbuilt.com' && (
                  <Tab label='Data Management' {...a11yProps(4)} />
                )}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {quoteLoading ? (
              <Loader />
            ) : (
              <OrderList admin={true} shipped={false} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {<TicketList admin />}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h1>Portal Users</h1>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.table} aria-label='simple table'>
                  <TableRow>
                    {['Portal User', 'email', 'Business Central Customer'].map((column) => (
                      <TableCell key={column} sortDirection={orderBy === column ? sortOrder : false}>
                        <TableSortLabel
                          active={orderBy === column}
                          direction={orderBy === column ? sortOrder : 'asc'}
                          onClick={() => handleSort(column)}
                        >
                          {column.charAt(0).toUpperCase() + column.slice(1)}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>Corporate Store</TableCell>
                    <TableCell>Portal Access</TableCell>
                    <TableCell>Store Access</TableCell>
                    <TableCell>Portal Admin</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList?.length > 0 &&
                    userList.map((user, index) => (
                      <TableRow key={user._id}>
                        <TableCell>{user.fname + ' ' + user.lname}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {bcLoading ? (
                            <Loader />
                          ) : (
                            bcCustomers && (
                              <Select
                                value={user.bc_customer_id}
                                onChange={(e) =>
                                  handleEditUser(
                                    e,
                                    index,
                                    'bc_customer_id',
                                    e.target.value
                                  )
                                }
                              >
                                {bcCustomers.map((cust) => (
                                  <MenuItem
                                    key={cust.bc_customer_id}
                                    value={cust.bc_customer_id}
                                  >
                                    {cust.bc_customer_displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={user.isCorporation}
                            onChange={(e) =>
                              handleEditUser(
                                e,
                                index,
                                'isCorporation',
                                e.target.checked
                              )
                            }
                            name='isCorporation'
                            id={'isCorporation' + user._id}
                            color='secondary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={user.isApproved}
                            disabled={
                              user.storeAccess ||
                              user.isAdmin ||
                              user.bc_customer_id === '000'
                            }
                            onChange={(e) =>
                              handleEditUser(
                                e,
                                index,
                                'isApproved',
                                e.target.checked
                              )
                            }
                            name='isApproved'
                            id={'isApproved' + user._id}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={user.storeAccess}
                            disabled={user.isAdmin || user.isApproved === false}
                            onChange={(e) =>
                              handleEditUser(
                                e,
                                index,
                                'storeAccess',
                                e.target.checked
                              )
                            }
                            name='storeAccess'
                            id={'storeAccess' + user._id}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={user.isAdmin}
                            disabled={user.isApproved === false}
                            onChange={(e) =>
                              handleEditUser(
                                e,
                                index,
                                'isAdmin',
                                e.target.checked
                              )
                            }
                            name='isAdmin'
                            id={'isAdmin' + user._id}
                            color='secondary'
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display='flex'>
              <ButtonGroup
                style={{
                  marginLeft: 'auto',
                  marginTop: 10,
                }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ marginRight: 5 }}
                  onClick={handleResetUsers}
                >
                  Reset Users
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  onClick={handleSaveUsers}
                >
                  Save Users
                </Button>
              </ButtonGroup>
            </Box>
          </TabPanel>
          {userInfo?.email.length > 0 &&
            pricingAccess.includes(userInfo.email) && (
              <TabPanel value={value} index={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h1>Pricing & Customer Tiers</h1>
                  </Grid>
                  <Grid item xs={6}>
                    <h2>Portal Pricings</h2>
                    <Grid container spacing={2}>
                      {portalPricings?.length > 0 &&
                        portalPricings.map((p, i) => (
                          <Grid item xs={12} key={i}>
                            <Box display='flex'>
                              <Card variant='outlined'>
                                <CardHeader title={p.portalPricingName} />
                                <CardContent>
                                  <FormControl
                                    className={classes.formControl}
                                    required
                                  >
                                    <TextField
                                      type='number'
                                      defaultValue={p.portalPricingValue}
                                      InputLabelProps={{ shrink: true }}
                                      label='Amount'
                                      onFocus={(e) => e.target.select()}
                                      onChange={(e) =>
                                        setPortalPricingValue(e.target.value)
                                      }
                                    />
                                  </FormControl>
                                  <FormControl
                                    className={classes.formControl}
                                    required
                                  >
                                    <InputLabel shrink>Method</InputLabel>
                                    <Select
                                      required
                                      defaultValue={p.portalPricingMethod}
                                      onChange={(e) =>
                                        setPortalPricingMethod(e.target.value)
                                      }
                                    >
                                      {p.portalPricingMethods?.map((m, i) => (
                                        <MenuItem
                                          key={i}
                                          value={m.portalPricingMethodValue}
                                        >
                                          {m.portalPricingMethodName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </CardContent>
                                <CardActions>
                                  <Button
                                    variant='contained'
                                    color='secondary'
                                    size='small'
                                    onClick={(e) =>
                                      handlePortalPricingSave(e, p)
                                    }
                                    style={{ marginLeft: 'auto' }}
                                  >
                                    Save
                                  </Button>
                                </CardActions>
                              </Card>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <h2>Customer Tiers</h2>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead
                          className={classes.table}
                          aria-label='simple table'
                        >
                          <TableRow>
                            <TableCell>BC Customer</TableCell>
                            <TableCell>Customer Tier</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {editedBcCustomers?.length > 0 &&
                            editedBcCustomers.map((c, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  {c.bc_customer_displayName}
                                </TableCell>
                                <TableCell>
                                  <FormControl
                                    className={classes.formControl}
                                    required
                                  >
                                    <Select
                                      name='customerTier'
                                      required
                                      value={c.customerTier?._id}
                                      onChange={(e) =>
                                        handleEditBcCustomer(
                                          e,
                                          i,
                                          'customerTier',
                                          e.target.value
                                        )
                                      }
                                    >
                                      {editedBcCustomersTiers?.map((t) => (
                                        <MenuItem key={t?._id} value={t?._id}>
                                          {t.customerTierName}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box display='flex'>
                      <ButtonGroup
                        style={{
                          marginLeft: 'auto',
                          marginTop: 10,
                        }}
                      >
                        <Button
                          variant='contained'
                          color='secondary'
                          size='small'
                          onClick={handleSaveBcCustomers}
                        >
                          Save BC Customers
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            )}
          {userInfo?.email.length > 0 && userInfo.email === 'devops@suncoastbuilt.com' && (
            <TabPanel value={value} index={4}>
              <h1>Data Management</h1>
              <Card className={classes.card}>
                <CardHeader title='Data Imports' />
                <CardActions>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled
                    onClick={(e) => importCustomersHandler(e)}
                  >
                    Import Customers
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => importItemsHandler(e)}
                  >
                    Import Items
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => importPricesHandler(e)}
                  >
                    Import Prices
                  </Button>
                </CardActions>
              </Card>
              <Card className={classes.card} style={{ marginTop: 15 }}>
                <CardHeader title='Data Exports' />
                <CardContent>
                  <Select
                    value={bcItemsPg}
                    onChange={(e) => handleBCItemsDispatch(e)}
                    displayEmpty
                  >
                    <MenuItem disabled value=''>
                      <em>Select a PriceGroup</em>
                    </MenuItem>
                    <MenuItem value='CAD'>CAD</MenuItem>
                    <MenuItem value='USD'>USD</MenuItem>
                    <MenuItem value='SUN'>SUN</MenuItem>
                  </Select>
                </CardContent>
                <CardActions>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={(e) => handleItemsFromPortal(e)}
                  >
                    Portal Items Export
                  </Button>
                </CardActions>
              </Card>
              <Card className={classes.card} style={{ marginTop: 15 }}>
                <CardHeader title='ItemsSheet Generator for BC' />
                <CardContent>
                  <form>
                    <FormControl>
                      <InputLabel shrink>UnitOM Import</InputLabel>
                      <Input
                        id='unitOMIn'
                        type={'file'}
                        label='UnitOM Import'
                        onChange={(e) => handleFileInput(e)}
                      />
                    </FormControl>
                    <FormControl style={{ marginLeft: 5 }}>
                      <InputLabel shrink>Sales Price Import</InputLabel>
                      <Input
                        id='salesPriceIn'
                        type={'file'}
                        onChange={(e) => handleFileInput(e)}
                      ></Input>
                    </FormControl>
                  </form>
                </CardContent>
                <CardActions>
                  {unitOM && salesPrice && (
                    <Button
                      variant='contained'
                      color='secondary'
                      style={{ marginTop: 15 }}
                      onClick={(e) => handleFileOutput(e)}
                    >
                      Download
                    </Button>
                  )}
                </CardActions>
              </Card>
            </TabPanel>
          )}
        </div>
      )}
    </>
  );
};

export { AdminView };
