import React, { useContext } from 'react';

import { StoreContext } from '../context';
import { Loader, Message } from '../components';

import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Switch,
  Avatar,
  Box,
  Checkbox,
} from '@material-ui/core';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import ScreenTabIconImage from '../assets/images/screensIcon.png';
import ScreenSideIndicatorImage from '../assets/images/retractableScreens1.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  splitCol: {
    display: 'flex',
  },
  screenSideIndicator: {
    maxWidth: '75%',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));

const fracToDec = (f) => {
  return Number(f) !== 0 ? parseFloat(f.split('/')[0] / f.split('/')[1]) : 0;
};

const dropDirection = (l, lf, r, rf) => {
  const left = Number(l) + fracToDec(lf);
  const right = Number(r) + fracToDec(rf);

  if (Number(left) > Number(right)) {
    return (
      <Typography
        color='error'
        variant='subtitle2'
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: 'auto',
        }}
      >
        Screen slopes down right to left. (
        <KeyboardBackspaceIcon />)
      </Typography>
    );
  } else if (Number(left) < Number(right)) {
    return (
      <Typography
        color='error'
        variant='subtitle2'
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: 'auto',
        }}
      >
        Screen slopes down left to right. (
        <ArrowRightAltIcon />)
      </Typography>
    );
  }
  return null;
};

const ScreenForm = ({ order, status }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);

  return (
    <div className={classes.paper}>
      <form
        className={classes.form}
        onSubmit={(e) => ctx.handleAddScrToCart(e, ctx.scr)}
      >
        <Grid container>
          <Grid container item sm={12} md={12}>
            <Box flexGrow={1} className={classes.headerBox}>
              <Typography
                color='inherit'
                variant='h6'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar src={ScreenTabIconImage} variant='square' /> Retractable
                Screens
              </Typography>
            </Box>
            <Box>
              <img
                src={ScreenSideIndicatorImage}
                alt='screen side indicator'
                className={classes.screenSideIndicator}
              />
            </Box>
            <Grid item xs={12}>
              <FormControl required fullWidth style={{ marginBottom: '5' }}>
                <Typography>
                  {ctx.scr.meshOnly ? 'Mesh' : 'Screen'} Name
                </Typography>
                <TextField
                  fullWidth
                  required
                  type='text'
                  value={ctx.scr.screenName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      screenName: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl style={{ marginBottom: '5' }}>
                <FormControlLabel
                  style={{ paddingTop: '10px' }}
                  control={
                    <Checkbox
                      size='small'
                      checked={ctx.scr.meshOnly}
                      onChange={(e) => {
                        ctx.setScr({
                          ...ctx.scr,
                          meshOnly: e.target.checked,
                        });
                      }}
                    />
                  }
                  label='Mesh Only?'
                />
              </FormControl>
            </Grid>
            <Grid container item sm={12} md={6}>
              <Typography style={{ paddingTop: '10px' }}>Options</Typography>
              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
              >
                <InputLabel shrink>Housing Size</InputLabel>
                <Select
                  margin='dense'
                  required={!ctx.scr.meshOnly}
                  disabled={ctx.scr.meshOnly}
                  value={ctx.scr.options.screenType}
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      options: {
                        ...ctx.scr.options,
                        screenType: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'5.5'}>
                    5.5" Housing (up to 22' wide & 11' drop)
                  </MenuItem>
                  <MenuItem value={'7'}>
                    7" Housing (up to 30' wide & 22' drop)
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
              >
                {ctx.scr.options.jobColor === 'MF' ? (
                  <InputLabel error shrink>
                    Indicate Job Color in Additional Options.
                  </InputLabel>
                ) : (
                  <InputLabel shrink>Job Color</InputLabel>
                )}
                <Select
                  margin='dense'
                  required={!ctx.scr.meshOnly}
                  disabled={ctx.scr.meshOnly}
                  value={ctx.scr.options.jobColor}
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      options: {
                        ...ctx.scr.options,
                        jobColor: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'Black'}>Black</MenuItem>
                  <MenuItem value={'Brown'}>Brown</MenuItem>
                  <MenuItem value={'Tan'}>Tan</MenuItem>
                  <MenuItem value={'Linen'}>Linen</MenuItem>
                  <MenuItem value={'Grey'}>Grey</MenuItem>
                  <MenuItem value={'MF'}>Custom *</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                {ctx.scr.options.meshType === 'Custom' ? (
                  <InputLabel error shrink>
                    Indicate Mesh Type in Additional Options.
                  </InputLabel>
                ) : (
                  <InputLabel shrink>Mesh Type</InputLabel>
                )}
                <Select
                  margin='dense'
                  required
                  value={ctx.scr.options?.meshType}
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      options: {
                        ...ctx.scr.options,
                        meshType: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'Suntex 80'}>Suntex 80</MenuItem>
                  <MenuItem value={'Suntex 90'}>Suntex 90</MenuItem>
                  <MenuItem value={'Suntex 95'}>Suntex 95</MenuItem>
                  <MenuItem value={'Vinyl'}>Vinyl</MenuItem>
                  <MenuItem value={'TuffScreen'}>
                    TuffScreen (Black Only)
                  </MenuItem>
                  <MenuItem value={'Custom'}>Custom *</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                {ctx.scr.options.meshColor === 'Custom' ? (
                  <InputLabel error shrink>
                    Indicate Mesh Color in Additional Options.
                  </InputLabel>
                ) : (
                  <InputLabel shrink>Mesh Color</InputLabel>
                )}
                <Select
                  margin='dense'
                  required
                  value={ctx.scr.options.meshColor}
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      options: {
                        ...ctx.scr.options,
                        meshColor: e.target.value,
                      },
                    })
                  }
                >
                  {ctx.scr.options.meshType === 'Custom' && (
                    <MenuItem value={'Custom'}>Custom *</MenuItem>
                  )}
                  {ctx.scr.options.meshType !== 'Custom' &&
                    ctx.scr.options.meshType !== 'Vinyl' && (
                      <MenuItem value={'Black'}>Black</MenuItem>
                    )}
                  {ctx.scr.options.meshType !== 'Custom' &&
                    ctx.scr.options.meshType !== 'TuffScreen' &&
                    ctx.scr.options.meshType !== 'Vinyl' && (
                      <MenuItem value={'Dark Bronze'}>Dark Bronze</MenuItem>
                    )}
                  {ctx.scr.options.meshType !== 'Custom' &&
                    ctx.scr.options.meshType !== 'TuffScreen' &&
                    ctx.scr.options.meshType !== 'Vinyl' && (
                      <MenuItem value={'Stucco'}>Stucco</MenuItem>
                    )}
                  {ctx.scr.options.meshType !== 'Custom' &&
                    ctx.scr.options.meshType !== 'TuffScreen' &&
                    ctx.scr.options.meshType !== 'Vinyl' &&
                    !ctx.scr.options.meshType.includes('95') && (
                      <MenuItem value={'Beige'}>Beige</MenuItem>
                    )}
                  {ctx.scr.options.meshType.includes('95') &&
                    ctx.scr.options.meshType !== 'Vinyl' && (
                      <MenuItem value={'Alpaca'}>Alpaca</MenuItem>
                    )}
                  {ctx.scr.options.meshType === 'Vinyl' && (
                    <MenuItem value={'Clear'}>Clear</MenuItem>
                  )}
                  {ctx.scr.options.meshType === 'Vinyl' && (
                    <MenuItem value={'Smoked'}>Smoked</MenuItem>
                  )}
                </Select>
              </FormControl>
              {ctx.scr.meshOnly ? (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink>Replacement Reason</InputLabel>
                  <Select
                    margin='dense'
                    required={ctx.scr.meshOnly}
                    disabled={!ctx.scr.meshOnly}
                    value={ctx.scr.meshOnlyReason}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        meshOnlyReason: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={'New Purchase'}>New Purchase</MenuItem>
                    <MenuItem value={'Mesh Defect'}>Mesh Defect</MenuItem>
                    <MenuItem value={'Zipper Weld Failure'}>
                      Zipper Weld Failure
                    </MenuItem>
                    <MenuItem value={'Ordering Error'}>Ordering Error</MenuItem>
                    <MenuItem value={'Custom'}>Custom</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink>Motor Type</InputLabel>
                  <Select
                    margin='dense'
                    required={!ctx.scr.meshOnly}
                    disabled={ctx.scr.meshOnly}
                    value={ctx.scr.options.motor}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          motor: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value={'Standard'}>Standard (21 rpm)</MenuItem>
                    <MenuItem value={'Fast'}>Fast (32 rpm)</MenuItem>
                    <MenuItem value={'Heavy'}>Heavy</MenuItem>
                  </Select>
                </FormControl>
              )}

              <FormControl
                className={classes.formControl}
                fullWidth
                style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
              >
                <InputLabel shrink>Motor Side</InputLabel>
                <Select
                  margin='dense'
                  required={!ctx.scr.meshOnly}
                  disabled={ctx.scr.meshOnly}
                  value={ctx.scr.options.motorSide}
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      options: {
                        ...ctx.scr.options,
                        motorSide: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'Left'}>Left</MenuItem>
                  <MenuItem value={'Right'}>Right</MenuItem>
                </Select>
              </FormControl>
              <Grid item xs={12} className={classes.splitCol}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
                >
                  <InputLabel shrink>Track Type</InputLabel>
                  <Select
                    margin='dense'
                    required={!ctx.scr.meshOnly}
                    disabled={ctx.scr.meshOnly}
                    value={ctx.scr.options.tracks}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          tracks: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value={'Standard'}>Standard</MenuItem>
                    <MenuItem value={'Recessed'}>Recessed</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  className={classes.formControl}
                  style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
                >
                  <FormControlLabel
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      // fontWeight: 400,
                      // letterSpacing: '0.00938em',
                    }}
                    control={
                      <Switch
                        disabled={ctx.scr.meshOnly}
                        checked={ctx.scr.options.mountingAngle}
                        size='small'
                        onChange={(e) =>
                          ctx.setScr({
                            ...ctx.scr,
                            options: {
                              ...ctx.scr.options,
                              mountingAngle: e.target.checked,
                            },
                          })
                        }
                      />
                    }
                    label='Mnt. Angle?'
                    labelPlacement='top'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.splitCol}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
                >
                  <InputLabel shrink>Bottom Bar Finish</InputLabel>
                  <Select
                    margin='dense'
                    required={!ctx.scr.meshOnly}
                    disabled={ctx.scr.meshOnly}
                    value={ctx.scr.options.barFinish}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          barFinish: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value={'2" Rubber'}>2" Rubber</MenuItem>
                    <MenuItem value={'3" Rubber'}>3" Rubber</MenuItem>
                    <MenuItem value={'1.5" Brush'}>1.5" Brush</MenuItem>
                    <MenuItem value={'None'}>None</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6}>
              <Typography style={{ paddingTop: '10px' }}>
                Measurements
              </Typography>
              {ctx.scr.meshOnly && (
                <InputLabel error shrink>
                  For mesh only orders, please provide the width of the housing
                  instead of the screen.
                </InputLabel>
              )}
              <Grid item xs={12} className={classes.splitCol}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    // margin='dense'
                    fullWidth
                    required
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 36,
                      },
                    }}
                    label='Width (Inches)'
                    value={Number(ctx.scr.measurements.width)}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        measurements: {
                          ...ctx.scr.measurements,
                          width: Number(e.target.value),
                        },
                      })
                    }
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink>(Fraction)</InputLabel>
                  <Select
                    value={ctx.scr.measurements.widthFraction}
                    required
                    fullWidth
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        measurements: {
                          ...ctx.scr.measurements,
                          widthFraction: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value={Number(0)}>0</MenuItem>
                    <MenuItem value={'1/8'}>1/8</MenuItem>
                    <MenuItem value={'1/4'}>1/4</MenuItem>
                    <MenuItem value={'3/8'}>3/8</MenuItem>
                    <MenuItem value={'1/2'}>1/2</MenuItem>
                    <MenuItem value={'5/8'}>5/8</MenuItem>
                    <MenuItem value={'3/4'}>3/4</MenuItem>
                    <MenuItem value={'7/8'}>7/8</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.splitCol}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    fullWidth
                    required
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 12,
                      },
                    }}
                    label='Drop Left (Inches)'
                    value={Number(ctx.scr.measurements.dropLeft)}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        measurements: {
                          ...ctx.scr.measurements,
                          dropLeft: Number(e.target.value),
                        },
                      })
                    }
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink>(Fraction)</InputLabel>
                  <Select
                    value={ctx.scr.measurements.dropLeftFraction}
                    required
                    fullWidth
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        measurements: {
                          ...ctx.scr.measurements,
                          dropLeftFraction: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value={'0'}>0</MenuItem>
                    <MenuItem value={'1/4'}>1/4</MenuItem>
                    <MenuItem value={'1/2'}>1/2</MenuItem>
                    <MenuItem value={'3/4'}>3/4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.splitCol}>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    fullWidth
                    required
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 12,
                      },
                    }}
                    label='Drop Right (Inches)'
                    value={Number(ctx.scr.measurements.dropRight)}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        measurements: {
                          ...ctx.scr.measurements,
                          dropRight: Number(e.target.value),
                        },
                      })
                    }
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink>(Fraction)</InputLabel>
                  <Select
                    value={ctx.scr.measurements.dropRightFraction}
                    required
                    fullWidth
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        measurements: {
                          ...ctx.scr.measurements,
                          dropRightFraction: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value={'0'}>0</MenuItem>
                    <MenuItem value={'1/4'}>1/4</MenuItem>
                    <MenuItem value={'1/2'}>1/2</MenuItem>
                    <MenuItem value={'3/4'}>3/4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.splitCol}>
                {dropDirection(
                  ctx.scr.measurements.dropLeft,
                  ctx.scr.measurements.dropLeftFraction,
                  ctx.scr.measurements.dropRight,
                  ctx.scr.measurements.dropRightFraction
                )}
              </Grid>
              <Grid item xs={12} className={classes.splitCol}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    required
                    value={Number(ctx.scr.cartCopies)}
                    type='number'
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    label='Screen Copies'
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      ctx.setScr({
                        ...ctx.scr,
                        cartCopies: Number(e.target.value),
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Box>
                <Typography color='error' variant='subtitle2'>
                  <em>
                    All additional items (remotes, angle for top slope, etc.)
                    need to be ordered separately from the Parts & Extras tab.
                  </em>
                </Typography>
              </Box>
              <FormControl
                className={classes.formControl}
                required
                fullWidth
                style={{ width: '100%' }}
              >
                <TextField
                  fullWidth
                  variant='outlined'
                  multiline
                  minRows={6}
                  type='text'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label='Additional Details'
                  value={ctx.scr.details}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) =>
                    ctx.setScr({ ...ctx.scr, details: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            <Button
              type='submit'
              className={classes.submit}
              fullWidth
              variant='contained'
              color='primary'
              disabled={ctx.isAdmin && order && status === 'Rejected'}
              size='medium'
            >
              {order || ctx.scr.screenId.length > 0
                ? 'Update Order'
                : 'Add to Order'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const StoreScreens = ({ order, status }) => {
  const ctx = useContext(StoreContext);
  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity='error'>{ctx.error}</Message>
      ) : (
        <ScreenForm order={order} status={status} />
      )}
    </>
  );
};

export { StoreScreens };
