const cartSubTotal = (cart) => {
  const partsSub =
    cart?.parts?.length > 0 &&
    cart.parts.reduce((acc, part) => acc + part.price * part.quantity, 0);
  const screensSub =
    cart?.screens?.length > 0 &&
    cart.screens.reduce((acc, screen) => acc + screen.price, 0);
  const roofsSub =
    cart?.roofs?.length > 0 &&
    cart.roofs.reduce((acc, roof) => acc + roof.price, 0);

  cart.parts.partsTotal = partsSub && partsSub > 0 ? partsSub : 0;
  cart.screens.screensTotal = screensSub && screensSub > 0 ? screensSub : 0;
  cart.roofs.roofsTotal = roofsSub && roofsSub > 0 ? roofsSub : 0;

  return cart;
};

const cartTotal = (cart) => {
  const { partsTotal } = cart.parts;
  const { screensTotal } = cart.screens;
  const { roofsTotal } = cart.roofs;

  cart.totalPrice =
    (partsTotal && partsTotal > 0 ? partsTotal : 0) +
    (screensTotal && screensTotal > 0 ? screensTotal : 0) +
    (roofsTotal && roofsTotal > 0 ? roofsTotal : 0);

  return cart;
};

export { cartSubTotal, cartTotal };
