import React, { useContext, useState } from 'react';

import { StoreContext } from '../context';
import { StoreScreens, StoreParts2, StoreRoofs2 } from '../components';

import {
  AppBar,
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledBox p={3}>{children}</StyledBox>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontFamily: ('Roboto', 'Helvetica', 'Arial', 'sans-serif'),
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
}));

const StyledBox = withStyles((theme) => ({
  root: {
    padding: 15,
  },
}))(Box);

const ScrTab = ({ value, cartInfo }) => {
  return (
    <TabPanel value={value} index={0}>
      <Card>
        <CardContent>
          <StoreScreens order={cartInfo?.orderId} status={cartInfo?.status} />
        </CardContent>
      </Card>
    </TabPanel>
  );
};
const RoofTab = ({ value }) => {
  return (
    <TabPanel value={value} index={1}>
      <Card>
        <CardContent>
          <StoreRoofs2 />
        </CardContent>
      </Card>
    </TabPanel>
  );
};
const PrtTab2 = ({ value, cartInfo }) => {
  return (
    <TabPanel value={value} index={2}>
      <Grid
        container
        item
        md={12}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <StoreParts2 />
      </Grid>
    </TabPanel>
  );
};

const StoreTabs = () => {
  const classes = useStyles();
  const cartInfo = useContext(StoreContext).cartInfo;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Retractable Screens' {...a11yProps(0)} />
          <Tab label='Louvered Roofs' {...a11yProps(1)} />
          <Tab label='Parts & Extras' {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <ScrTab value={value} cartInfo={cartInfo} />
      <PrtTab2 value={value} cartInfo={cartInfo} />
      <RoofTab value={value} />
    </div>
  );
};

export { StoreTabs };
