import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { forEach, now } from 'lodash';
import XLSX from 'xlsx';
import { format } from 'date-fns';

import { clearCart } from '../actions';
import { StoreContext } from '../context';
import { DialogOrderAdminProcess } from '../components';

import { makeStyles, Button, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btns: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  exportBtn: {
    marginRight: 5,
  },
  closeBtn: {
    marginRight: 'auto',
  },
}));

const CartButtonsAdmin = () => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { cartInfo, isAdmin } = ctx;
  const { status, _id } = cartInfo;
  const { screens, roofs, parts } = cartInfo;

  // console.log('ctx: ', ctx);

  const handleOrderExport = async (e) => {
    e.preventDefault();

    const fileName = `${format(new Date(now()), 'yyyyMMdd')}_${
      ctx?.cartInfo?.orderName
    }.xlsx`;

    const orderExport = (type) => {
      let orderDetails = [];
      let scrDetails = [];
      let scrKits = [];
      let rfDetails = [];
      let rfKits = [];
      let prtLines = [];

      switch (type) {
        case 'details':
          orderDetails.push({
            'Dealer Name': ctx?.cartInfo?.userDetails?.bc_customer_displayName,
            'User Name': ctx?.cartInfo?.userDetails?.userName,
            'Price Group': ctx?.cartInfo?.userDetails?.bc_customer_priceGroup,
            'Order Name': ctx?.cartInfo?.orderName,
            'Order Number': ctx?.cartInfo?.orderNumber,
            'Order Date': format(
              new Date(ctx?.cartInfo?.createdAt),
              'MMM dd, yyyy'
            ),
            'Last Updated': format(
              new Date(ctx?.cartInfo?.updatedAt),
              'MMM dd, yyyy'
            ),
            'Extra Details for Parts': ctx?.cartInfo?.extraDetails,
          });
          return { orderDetails };

        case 'screens':
          forEach(screens, (scr) => {
            scrDetails.push({
              'Screen Name': scr.screenName,
              'Screen Type': scr.options.screenType,
              'Job Color': scr.options.jobColor,
              'Mesh Only': scr.meshOnly ? 'Yes' : 'No',
              'Mesh Only Reason': scr.meshOnlyReason,
              'Mesh Type': scr.options.meshType,
              'Mesh Color': scr.options.meshColor,
              Motor: scr.options.motor,
              'Motor Side': scr.options.motorSide,
              Tracks: scr.options.tracks,
              'Bar Finish': scr.options.barFinish,
              'Mounting Angle': scr.options.mountingAngle,
              'Screen Width': scr.measurements.widthDisplay,
              'Drop Left': scr.measurements.dropLeftDisplay,
              'Drop Right': scr.measurements.dropRightDisplay,
              'Additional Details': scr.details,
            });
            forEach(scr.mappedScrKits, (kit) => {
              scrKits.push({
                'Screen Name': scr.screenName,
                'Master Number': String(kit.number),
                'Kit Category Code': kit.itemCategoryCode,
                'Kit Name': kit.displayName,
              });
            });
          });
          return { scrDetails, scrKits };

        case 'roofs':
          // console.log('roofs', roofs);
          forEach(roofs, (rf) => {
            rfDetails.push({
              'Roof Name': rf.roofName,
              'Motor Type': rf.motorType,
              'Motor Quantity': rf.motorQuantity,
              'Frame Color': rf.colors.frameColor,
              'Blade Color': rf.colors.bladeColor,
              'Post Color': rf.colors.postColor,
            });
            forEach(rf.frames, (frame, i) => {
              rfDetails[0][`Frame${i} Type`] = frame.frameType;
              rfDetails[0][`Frame${i} Feet`] = frame.frameFeet;
              rfDetails[0][`Frame${i} Inches`] = frame.frameInches;
            });
            forEach(rf.beamMs, (beam, i) => {
              rfDetails[0][`Mid Beam${i} Type`] = beam.beamType;
              rfDetails[0][`Mid Beam${i} Feet`] = beam.beamFeet;
              rfDetails[0][`Mid Beam${i} Inches`] = beam.beamInches;
            });
            forEach(rf.beamAs, (beam, i) => {
              rfDetails[0][`Acc Beam${i} Type`] = beam.beamType;
              rfDetails[0][`Acc Beam${i} Feet`] = beam.beamFeet;
              rfDetails[0][`Acc Beam${i} Inches`] = beam.beamInches;
            });
            forEach(rf.beamXs, (beam, i) => {
              rfDetails[0][`Extra Beam${i} Type`] = beam.beamType;
              rfDetails[0][`Extra Beam${i} Feet`] = beam.beamFeet;
              rfDetails[0][`Extra Beam${i} Inches`] = beam.beamInches;
            });
            forEach(rf.blades, (blade, i) => {
              rfDetails[0][`Blade${i} Type`] = blade.bladeType;
              rfDetails[0][`Blade${i} Feet`] = blade.bladeFeet;
              rfDetails[0][`Blade${i} Inches`] = blade.bladeInches;
              rfDetails[0][`Blade${i} Qty`] = blade.bladeQty;
            });
            forEach(rf.posts, (post, i) => {
              rfDetails[0][`Post${i} Style`] = post.postStyle;
              rfDetails[0][`Post${i} Length`] = post.postLength;
            });
            forEach(rf.addParts, (part, i) => {
              rfDetails[0][`Add Part${i} Name`] = part.partName;
              rfDetails[0][`Add Part${i} Qty`] = part.partQty;
            });
          });
          return {
            rfDetails,
            rfKits,
          };

        case 'parts':
          // console.log('parts', parts);
          forEach(parts, (prt) => {
            prtLines.push({
              'Part Name': prt.displayName,
              'Master Number': String(prt.number),
              Quantity: prt.quantity,
              Price: prt.price,
              'Line Total': Number(prt.quantity) * Number(prt.price),
            });
          });
          return { prtLines };

        default:
          return '';
      }
    };

    const orderDetailsWorksheet = XLSX.utils.json_to_sheet(
      orderExport('details').orderDetails
    );
    const screenDetailsWorksheet = XLSX.utils.json_to_sheet(
      orderExport('screens').scrDetails
    );
    const screenKitsWorksheet = XLSX.utils.json_to_sheet(
      orderExport('screens').scrKits
    );
    const roofDetailsWorksheet = XLSX.utils.json_to_sheet(
      orderExport('roofs').rfDetails
    );
    const roofKitsWorksheet = XLSX.utils.json_to_sheet(
      orderExport('roofs').rfKits
    );
    const partsWorksheet = XLSX.utils.json_to_sheet(
      orderExport('parts').prtLines
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      orderDetailsWorksheet,
      'Order Details'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      screenDetailsWorksheet,
      'Screens List'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      screenKitsWorksheet,
      'Screen Kits List'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      roofDetailsWorksheet,
      'Roof Details List'
    );
    XLSX.utils.book_append_sheet(workbook, roofKitsWorksheet, 'Roof Kits List');
    XLSX.utils.book_append_sheet(workbook, partsWorksheet, 'Parts List');
    XLSX.writeFile(workbook, fileName);
  };

  const handleCloseOrder = () => {
    dispatch(clearCart());
    history.push('/admin');
    window.location.reload();
  };

  const displayAdminButtons = () => {
    let statusesForDisplay = ['Submitted', 'Approved'];
    return isAdmin && _id && statusesForDisplay.includes(status);
  };

  return displayAdminButtons() ? (
    <Box className={classes.btns}>
      <Button
        className={classes.closeBtn}
        variant='contained'
        color='secondary'
        size='small'
        onClick={handleCloseOrder}
      >
        Close Order
      </Button>
      <Button
        className={classes.exportBtn}
        variant='contained'
        color='primary'
        size='small'
        onClick={(e) => handleOrderExport(e)}
      >
        Export Cart
      </Button>
      <DialogOrderAdminProcess cart={cartInfo} />
    </Box>
  ) : null;
};

export { CartButtonsAdmin };
