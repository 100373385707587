import axios from 'axios';
import ObjectID from 'bson-objectid';
import { toast } from 'react-toastify';

import {
  CART_ADD_SCREEN,
  CART_ADD_ROOF,
  CART_ADD_PART,
  CART_ADD_FROM_ORDER,
  CART_REMOVE_SCREEN,
  CART_REMOVE_ROOF,
  CART_REMOVE_PART,
  CART_CLEAR_ITEMS,
} from '../constants';

import { generateOrderId } from '../utils/idGenerators';

export const addToCart =
  (isCart, screen, roof, part) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    // console.log('cart_id: ', await generateOrderId(userInfo.token));
    console.log('isCart: ', isCart);

    const _id =
      isCart?._id?.length > 0
        ? isCart._id
        : await generateOrderId(userInfo.token);
    const cartId =
      isCart?.cartId?.length > 0 ? isCart.cartId : ObjectID().toString();
    const orderId = isCart?.orderId?.length > 0 ? isCart.orderId : null;
    const orderName = isCart?.orderName?.length > 0 ? isCart.orderName : null;
    const transfer = isCart?.transfer ? isCart.transfer : false;
    const orderNumber =
      isCart?.orderNumber?.length > 0 ? isCart.orderNumber : null;
    const status = isCart?.status?.length > 0 ? isCart.status : 'In Cart';
    const user = isCart?.user?.length > 0 ? isCart.user : userInfo._id;
    const userName =
      isCart?.userName?.length > 0
        ? isCart.userName
        : `${userInfo.fname} ${userInfo.lname}`;
    const email = isCart?.email?.length > 0 ? isCart.email : userInfo.email;
    const bc_customer_id =
      isCart?.bc_customer_id?.length > 0
        ? isCart.bc_customer_id
        : userInfo.bc_customer_id;
    const bc_customer_displayName =
      isCart?.bc_customer_displayName?.length > 0
        ? isCart.bc_customer_displayName
        : userInfo.bc_customer_displayName;
    const bc_customer_priceGroup =
      isCart?.bc_customer_priceGroup?.length > 0
        ? isCart.bc_customer_priceGroup
        : userInfo.bc_customer_priceGroup;
    const bc_customer_tierRate =
      isCart?.bc_customer_tierRate > 0
        ? isCart.bc_customer_tierRate
        : userInfo.bc_customer_tierRate;

    let newScreen = {};
    let newRoof = {};
    let newPart = {};
    let type = '';

    if (screen != null) {
      if (screen._id?.length > 1) {
        newScreen = screen;
      } else {
        const {
          screenId,
          screenName,
          options,
          measurements,
          mappedScrKits,
          meshOnly,
          meshOnlyReason,
          price,
          details,
        } = screen;
        newScreen = {
          screenId,
          screenName,
          options,
          measurements,
          mappedScrKits,
          meshOnly,
          meshOnlyReason,
          price,
          details,
        };
      }
      type = CART_ADD_SCREEN;
    }
    if (roof != null) {
      if (roof._id?.length > 1) {
        newRoof = roof;
      } else {
        const {
          roofId,
          roofName,
          colors,
          motorType,
          motorQuantity,
          frames,
          beamMs,
          beamAs,
          beamXs,
          blades,
          posts,
          addParts,
          mappedRfKits,
          price,
          details,
          file,
          fileName,
        } = roof;
        newRoof = {
          roofId,
          roofName,
          colors,
          motorType,
          motorQuantity,
          frames,
          beamMs,
          beamAs,
          beamXs,
          blades,
          posts,
          addParts,
          mappedRfKits,
          price,
          details,
          file,
          fileName,
        };
      }
      type = CART_ADD_ROOF;
    }
    if (part != null) {
      newPart = part;
      type = CART_ADD_PART;
    }

    dispatch({
      type: type,
      payload: {
        _id: _id,
        cartId: cartId,
        orderId: orderId,
        orderName: orderName,
        orderNumber: orderNumber,
        transfer: transfer,
        user: user,
        userDetails: {
          userName: userName,
          email: email,
          bc_customer_id: bc_customer_id,
          bc_customer_displayName: bc_customer_displayName,
          bc_customer_priceGroup: bc_customer_priceGroup,
          bc_customer_tierRate: bc_customer_tierRate,
        },
        screen: newScreen,
        roof: newRoof,
        part: newPart,
        totalPrice: 0.0,
        status: status,
      },
    });

    // toast.info(`${_.isEmpty(newPart) ? 'Screen' : 'Part'} added to cart`);

    toast.success(`Item added to cart`, {
      toastId: cartId,
    });

    localStorage.setItem('cartInfo', JSON.stringify(getState().cart.cartInfo));
  };

export const addOrderToCart = (orderId) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
    proxy: {
      host: 'localhost',
      port: 5000,
    },
  };

  const { data } = await axios.get(`/api/orders/${orderId}`, config);

  dispatch({
    type: CART_ADD_FROM_ORDER,
    payload: {
      cartId: ObjectID().toString(),
      _id: data._id,
      orderId: data.orderId,
      orderName: data.orderName,
      salesOrderNumber: data.salesOrderNumber,
      orderNumber: data.orderNumber,
      transfer: data.transfer,
      user: data.user,
      userDetails: {
        userName: data.userDetails.userName,
        email: data.userDetails.email,
        bc_customer_id: data.userDetails.bc_customer_id,
        bc_customer_displayName: data.userDetails.bc_customer_displayName,
        bc_customer_priceGroup: data.userDetails.bc_customer_priceGroup,
        bc_customer_tierRate: data.userDetails.bc_customer_tierRate,
      },
      screens: data.screens,
      roofs: data.roofs,
      parts: data.parts,
      totalPrice: data.totalPrice,
      extraDetails: data.extraDetails,
      status: data.status,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    },
  });
  localStorage.setItem('cartInfo', JSON.stringify(getState().cart.cartInfo));
  localStorage.setItem('orderInfo', JSON.stringify(getState().cart.orderInfo));
};

export const removeFromCart = (i, id) => async (dispatch, getState) => {
  let type = '';

  if (i === 's') {
    type = CART_REMOVE_SCREEN;
  } else if (i === 'r') {
    type = CART_REMOVE_ROOF;
  } else if (i === 'p') {
    type = CART_REMOVE_PART;
  }

  dispatch({
    type: type,
    payload: id,
  });
  localStorage.setItem('cartInfo', JSON.stringify(getState().cart.cartInfo));
};

export const clearCart = () => (dispatch) => {
  localStorage.removeItem('cartInfo');
  localStorage.removeItem('orderInfo');
  dispatch({ type: CART_CLEAR_ITEMS });
};
